.home {
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
}
.summer {
  width: 240px;
  position: absolute;
  top: 100px;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  pointer-events: all;
}
.title {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 10px;
}
.title .titleCount {
  font-size: 18px;
  line-height: 35px;
  color: white;
  text-align: center;
  flex-grow: 1;
  border-radius: 6px;
  margin: 0 6px;
}
.segmentationBlock {
  height: 100%;
  overflow: auto;
  padding: 8px;
}
.segmentationBlock .segmentation {
  padding: 6px 12px;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #355b6d;
  border-radius: 3px;
}
.segmentationBlock .segmentation i {
  font-size: 30px;
  display: block;
  margin-right: 10px;
}
.segmentationBlock .segmentation .numbers {
  font-size: 14px;
  padding: 2px 6px;
  border-radius: 4px;
  display: block;
  text-align: center;
}
.titles {
  font-size: 18px;
}
.legends {
  position: absolute;
  width: 212px;
  bottom: 20px;
  right: 750px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: #FFF;
  padding: 6px;
}
.legends:empty {
  display: none;
}
.legends .legend {
  width: 100px;
}
.legends .legend code {
  margin-right: 4px;
}
