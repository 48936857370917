
@import "../variables.less";

.home {
	width: 100%;
	height: 100%;
	overflow: hidden;
	pointer-events: none;
}

.summer {
	width: 240px;
	position: absolute;
	top: 100px;
	bottom: 20px;
	right: 20px;
	display: flex;
	flex-direction: column;
	pointer-events: all;
}

.title {
	display: flex;
	flex-direction: row;
	width: 100%;
	padding: 10px;

	.titleCount {
		font-size: @fontSizeB;
		line-height: 35px;
		color: white;
		text-align: center;
		flex-grow: 1;
		border-radius: 6px;
		margin: 0 6px;
	}
}

.segmentationBlock {
	height: 100%;
	overflow: auto;
	padding: 8px;

	.segmentation {
		padding: 6px 12px;
		color: white;
		display: flex;
		flex-direction: row;
		align-items: center;
		border: 1px solid #355b6d;
		border-radius: 3px;

		i {
			font-size: 30px;
			display: block;
			margin-right: 10px;
		}

		.numbers {
			font-size: @fontSizeC;
			padding: 2px 6px;
			border-radius: 4px;
			display: block;
			text-align: center;
		}
	}
}

.titles {
	font-size: @fontSizeB;
}

.legends {
	position: absolute;
	width: 200px + 2 * @middleSpace;
	bottom: 20px;
	right: 750px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	color: #FFF;
	padding: @middleSpace;

	&:empty {
		display: none;
	}

	.legend {
		width: 100px;

		code {
			margin-right: @smallSpace;
		}
	}
}
